import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const MainHeaderStyled = styled.div`
    padding-top: 20px;
    position: relative;
    z-index: 20;

    .action-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        .action-title {
            font-size: 15px;
            color: ${(props) => (props.mode ? "#fff" : "#000")};
        }

        .action-close {
            font-size: 15px;
            display: flex;
            justify-content: center;
            color: #000;

            a {
                color: #000;
                padding: 5px 10px;
                background: #2a10e1;
                color: #fff;
                border-radius: 5px;
            }

            span {
                color: #000;
                font-weight: 500;
                font-size: 18px;
                margin-left: 7px;
            }
        }
    }

    .links-holder {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 20px;
        width: 100%;
        justify-content: space-between;
        grid-area: navbar;

        .visualize-active {
            background: #06edc2;
            /* background: none; */
            border: 0;
            /* border: 1px solid rgba(0, 0, 0, 0.15); */
            color: ${(props) => (!props.mode ? "#000" : "#fff")};
        }

        .extract-active {
            background: #f04f45;
            border: 0;
            color: ${(props) => (!props.mode ? "#000" : "#fff")};
        }

        .clone-active {
            background: #ff7b38;
            border: 0;
            color: ${(props) => (!props.mode ? "#000" : "#fff")};
        }

        .migrate-active {
            background: #fdd800;
            border: 0;
            color: ${(props) => (!props.mode ? "#000" : "#fff")};
        }
        .disable {
            cursor: not-allowed;
            filter: grayscale(100%);
        }
    }
`;

export const NavLinkMainStyled = styled(NavLink)`
    border-radius: 50px;
    padding: 15px 20px;
    // border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    transition: all 400ms ease-out;

    .disable {
        cursor: not-allowed;
        filter: grayscale(100%);
    }

    // Styles for the active link
    &.extract-active {
        background-color: #f04f45; // Background color for Extract link
        color: #000000; // Text color for active state
    }

    &.visualize-active {
        background-color: #06edc2; // Background color for Visualize link
        color: #fff; // Text color for active state
    }

    .nav-text {
        letter-spacing: 1.5px;
        font-weight: 600;
        font-size: 15px;
        color: #fff;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .nav {
            font-size: 1rem;
            color: rgba(0, 0, 0, 0.15);
        }

        .text {
            font-size: 0.7rem;
            position: relative;
            top: -3px;
            color: rgba(0, 0, 0, 0.15);
        }
    }

    &:hover {
        text-decoration: none;
        outline: 0;
        color: initial;
    }

    &:focus {
        outline: 0;
    }

    .icon-holder {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
    }

    .icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 10px;
    }

    .visualize-icon {
        background: #009bc0;
    }

    .extract-icon {
        background: #e37915;
    }

    .clone-icon {
        background: #f04f45;
    }

    .migrate-icon {
        background: #fdd800;
    }
`;
