import styled from "styled-components";

const CloudCounterStyled = styled.div`
    color: #fff;
    width: 100%;
    height: 79vh;
    grid-area: content;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1.1fr 3fr;
    grid-column-gap: 30px;
    grid-template-areas: "selected-project operation-area" "selected-project  operation-area";

    &.cloud {
    }
`;

export { CloudCounterStyled };
