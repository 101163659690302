import React, { useState, useEffect } from "react";
import { RadioWrapper } from "./checkbox.style";
import { useDispatch } from "react-redux";
import { setCurrentProject, setIsSelected } from "store/sc";
import useProjects from "helpers/custom-hooks/projects";
import localForage from "localforage";

const RadioInput = ({
    config,
    project,
    radius,
    projectName,
    projectKey,
    index,
    isOnBoarding,
}) => {
    const [checked, setChecked] = useState(false);
    const dispatch = useDispatch();
    const { getProject } = useProjects();

    useEffect(() => {
        const checkChange = async (e) => {
            const radio = document.querySelector(
                `input[radioindex=radio-${index}]`
            );

            const clickedEl = e.target;
            const wrapperArr = document.querySelectorAll(".radio-btn");
            if (Array.from(wrapperArr).includes(clickedEl)) {
                if (radio && clickedEl === radio.parentElement.parentElement) {
                    const res = await localForage.getItem(
                        "single_project_credentials"
                    );
                    setChecked(true);
                    dispatch(setIsSelected());
                    dispatch(setCurrentProject(project));
                    getProject(projectKey, res);
                } else {
                    setChecked(false);
                }
            }
        };

        const radio = document.querySelector(
            `input[radioindex=radio-${index}]`
        );

        radio.parentElement.parentElement.parentElement.addEventListener(
            "click",
            checkChange
        );
    }, [projectKey, project, getProject, dispatch, index]);

    return (
        <RadioWrapper
            radius={radius}
            isOnBoarding={isOnBoarding}
            checked={checked}
            radioindex={`radio-${index}`}
            className="radio-btn"
        >
            <div className="input-container">
                <input type="radio" {...config} radioindex={`radio-${index}`} />
                <span className="checkmark-container">
                    {checked && <span className="checkmark"></span>}
                </span>
            </div>
            <div className="project-des">
                <span className="project-name">
                    {projectName.toLowerCase()}
                </span>
                {isOnBoarding && (
                    <span className="project-key">{projectKey}</span>
                )}
            </div>
        </RadioWrapper>
    );
};

export default RadioInput;
