import { useState } from "react";
import { useSnackbar } from "notistack";
import Axios from "axios";
import useReponseErr from "../reponseErr";
import { useSelector } from "react-redux";

function useFilters() {
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { responseErr } = useReponseErr();
    const [data, setData] = useState("");
    const [filterData, setFilterData] = useState("");
    const token = useSelector((state) => state.project.bodyToken);

    function filters(values) {
        setLoading(true);
        Axios.post("/visualize/filter", { ...values, ...token })
            .then((res) => {
                setData(res.data);
                enqueueSnackbar("Successful", { variant: "success" });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                responseErr(err);
            });
    }

    function getFiltersData(values) {
        setLoading(true);
        Axios.post("/visualize/filter", { ...values, ...token })
            .then((res) => {
                setFilterData(res.data);
                enqueueSnackbar("Successful", { variant: "success" });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                responseErr(err);
            });
    }

    if (!loading) {
        closeSnackbar("key");
    } else {
        enqueueSnackbar("Getting visualization Data", {
            persist: true,
            variant: "default",
            key: "key",
        });
    }

    return {
        loading,
        data,
        filters,
        filterData,
        getFiltersData,
    };
}

export default useFilters;
