import styled from "styled-components";

export const OperationStyled = styled.div`
    padding: 30px;
    color: ${(props) => (props.mode ? "#fff" : "#2a10e1")};

    .heading {
        color: #009bc0;
        font-size: 24px;
        line-height: 32px;
    }
    .mini-cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 50px;
        grid-column-gap: 50px;
        width: 50%;
        justify-items: center;
        align-content: center;
    }

    .leading-text {
        font-size: 30px;
        font-weight: 400;
    }

    .operation-content {
        display: flex;
        align-items: center;
        height: 100%;
        flex-direction: column;
        width: 100%;
        position: relative;

        .alert-box {
            position: absolute;
            top: 35%;
            padding: 5px 0px;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
            transition: top 300ms ease;

            width: 300px;
            border-radius: 5px;
            background: #fff;
            border: 1px solid #4bb5434d;
            z-index: 9999999;
            border-radius: 5px;
            border-left: 5px solid #4bb543;

            .title {
                padding-left: 10px;
                color: black;
                font-weight: 300;
                color: #009bc0;
            }

            .close {
                position: absolute;
                right: 10px;
                color: #000;
                cursor: pointer;
            }

            h2 {
                font-size: 14px;
                margin: 0;
                font-weight: 600;
                padding: 5px 0;
                border-top: 1px solid #4bb5434d;

                span {
                    font-weight: 300;

                    &:nth-child(2) {
                        margin-right: 20px;
                    }
                }
            }

            .alert-content {
                background: #fff;
                padding: 10px;
                border-radius: 5px;
            }
        }

        .chart-operations {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .dots {
                .dot {
                    height: 7px;
                    width: 7px;
                    display: inline-block;
                    border-radius: 50%;
                    background: #fff;
                    border: 1px solid #06edc2;
                    cursor: pointer;
                    margin-left: 10px;
                    outline: 0;
                    transition: background 600ms ease;
                }

                .empty-dot {
                    background: #06edc2;
                }
            }

            .expand {
                cursor: pointer;
                background: #06edc2;
                border-radius: 20px;
                padding: 1.4px 15px;
                font-size: 14px;
                outline: 0;
                border: 0;

                &:focus {
                    outline: 0;
                }
            }

            .handle-chart-position {
                button {
                    cursor: pointer;
                    border: 1px solid #06edc2;
                    border-radius: 20px;
                    padding: 1.4px 15px;
                    transition: background 300ms ease;
                    font-size: 14px;
                    color: ${(props) => (props.mode ? "#2a10e1" : "#2a10e1")};

                    &:focus {
                        outline: 0;
                    }

                    &:hover {
                        background: #06edc2;
                    }

                    &:nth-child(2) {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    .btn-holder {
        // position: absolute;
        bottom: 0;
        cursor: pointer;
    }

    .operation-btn {
        background: #e37915;
        padding: 12px 32px;
        line-height: 24px;
        color: #fff;
        outline: 0;
        border-radius: 12px;
        border: 0;
        font-size: 16px;
        cursor: pointer;
        font-weight: 600;
        margin-top: 16px;
    }

    .operation-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .title {
            color: ${(props) => (props.mode ? "#fff" : "#009bc0")};
            font-size: 18px;
        }

        .other-info {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                margin-left: 9px;
            }

            .project-id,
            .project-date,
            .project-time {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 20px;
                font-size: 14px;
                color: #009bc0;
            }
        }

        .project-icon,
        .calendar-icon,
        .time-icon {
            height: 30px;
            width: 30px;
        }

        .project-icon {
            border-radius: 50%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 3px solid #374cd0;

            &::after {
                width: 50%;
                height: 50%;
                position: absolute;
                border-radius: 50%;
                content: "";
                border: 3px solid #374cd0;
            }
        }
    }

    .visualise-data-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        align-items: center;
    }

    .clone-data-container {
        justify-content: space-between;
    }

    .clone-data {
    }

    .visualize-data {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        color: #fff;
        height: 390px;
        overflow-y: scroll;
        margin-top: 20px;
        width: 100%;

        &.full-width-data {
            display: flex;
        }

        table {
            border: 1px solid #8080802b;
            width: 390px;
            min-width: 300px;
            border-radius: 10px;
            border-collapse: collapse;

            thead {
                background: #009bc0;
                color: #fff;
                border-radius: 5px;
            }

            th,
            td {
                padding: 9px 10px;
            }

            tr {
                border-radius: 5px;

                &:nth-child(2n) {
                    background: #1e384f;
                }
            }

            tbody {
                height: 350px;
                overflow-y: scroll;
            }
        }

        table tr:last-child td:first-child {
            border-bottom-left-radius: 10px;
        }

        table tr:last-child td:last-child {
            border-bottom-right-radius: 10px;
        }
    }

    .operation-visualize {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        color: #000;
    }

    .operation-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        color: #000;

        &.operation-extract {
            flex-direction: column;
        }

        .swap-icon {
            font-size: 30px;
            color: #fdd700;
            margin: 0 30px;
        }

        .coming-soon-holder {
            text-align: center;

            .coming-soon-text {
                color: #f04f46;
                font-weight: 100px;
                font-size: 36px;
                border-radius: 10px;
                animation: pulse 2s infinite;
            }

            p {
                letter-spacing: 1.2px;
                font-size: 14px;
            }

            @keyframes pulse {
                0% {
                    transform: scale(0.95);
                    /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); */
                }

                70% {
                    transform: scale(1.05);
                    /* box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); */
                }

                100% {
                    transform: scale(0.95);
                    /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
                }
            }
        }

        .operation-box {
            width: 200px;
            height: 210px;

            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            border-radius: 30px;
            border: 1px dashed #009bc0;
            cursor: pointer;

            .add-icon {
                height: 50px;
                width: 1px;
                color: #000;
                background: #009bc0;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    transform: rotate(90deg);
                    height: 50px;
                    width: 1px;
                    color: #000;
                    background: #009bc0;
                }
            }
        }

        .operation-box {
            overflow: hidden;
        }

        .project-details {
            height: 230px;
            padding: 20px;
            background: ${(props) => (!props.mode ? "#1e384f" : "#2a10e1")};
            border-radius: 30px;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
            width: 200px;
            display: grid;
            cursor: move;
            color: ${(props) => (props.mode ? "#fff" : "#009bc0")};

            .project-name-id {
                margin-bottom: 10px;
            }

            .project-name {
                color: #2a10e1;
                margin-bottom: 5px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 1px;
                text-transform: capitalize;
                color: ${(props) => (props.mode ? "#fff" : "#009bc0")};
            }
        }

        .mb-3 {
            position: relative;
            top: 40px;
        }

        .num {
            color: #2a10e1;
            font-size: 18px;
            font-weight: 600;
            color: ${(props) => (props.mode ? "#fff" : "#009bc0")};
        }

        .small {
            font-size: 14px;
            font-weight: 400;
            word-break: break-all;
        }

        .holder {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            .download-icon {
                fill: #fff;
                width: 25px;
                height: 25px;
                align-self: flex-end;
                opacity: 0.2;
            }

            .clone-icon {
                fill: #ff7b38;
                width: 25px;
                height: 25px;
                align-self: flex-end;
                opacity: 0.2;
            }
        }

        .grey-primary-text {
            color: #d8d8d7;
            font-size: 14px;
            font-weight: 600;
        }

        .grey-secondary-text {
            color: #b6b6b6;
            font-size: 14px;
        }
    }
`;

export const SelectProjectText = styled.div`
    font-size: 21px;
    margin: auto;
    color: #fff;
`;
