import React from "react";

export default function InitilizeMigrate({ setPage }) {
    return (
        <div className="operation-visualize">
            <div className="operation-box" onClick={() => setPage(2)}>
                <div className="add-icon" />
                <span className="grey-secondary-text mb-3">Add Instance</span>
            </div>

            <div style={{ marginTop: 12 }} className="btn-holder">
                <button className="operation-btn" onClick={() => setPage(2)}>
                    Proceed
                </button>
            </div>
        </div>
    );
}
