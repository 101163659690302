import React, { useEffect } from "react";
import { SelectProjectText } from "./cone.style";
import localForage from "localforage";
import { ReactComponent as CloneIcon } from "assets/Icons/copy.svg";
import useClone from "helpers/custom-hooks/clone";
import { useSelector } from "react-redux";
import { Spinner } from "components/Spinner/Spinner";

function CloneProject({ mode, setPage }) {
    const { clone, isCloning, cloneSuccess } = useClone();
    const currentProject = useSelector((state) => state.project.currentProject);

    function handleDragOver(e) {
        e.preventDefault();
    }

    function handleDropZone(e) {
        e.preventDefault();
        localForage.getItem("single_project_credentials").then((res) => {
            clone({ ...res, project_key: currentProject.key });
        });
    }

    useEffect(() => {
        if (cloneSuccess) {
            setPage(2);
        }
    }, [cloneSuccess, setPage]);

    return (
        <div className="operation-content">
            {currentProject && Object.keys(currentProject).length === 0 ? (
                <SelectProjectText>Select a project to clone</SelectProjectText>
            ) : (
                <div className="operation-body">
                    <div className="project-details" draggable={!isCloning}>
                        <div className="project-name-id">
                            <h3 className="project-name">
                                {currentProject && currentProject.name}
                            </h3>
                            <span className="grey-secondary-text">
                                {currentProject && currentProject.key}
                            </span>
                        </div>
                        <div className="">
                            <h3 className="grey-primary-text">
                                No of IssueTypes
                            </h3>
                            <span className="num">
                                {currentProject &&
                                    currentProject.NoOfIssueTypes}
                            </span>
                        </div>
                        <div className="holder">
                            <div className="">
                                <h3 className="grey-primary-text">
                                    No of Issues
                                </h3>
                                <span className="num">
                                    {currentProject &&
                                        currentProject.NoOfIssues}
                                </span>
                            </div>
                            <CloneIcon className="clone-icon" />
                        </div>
                    </div>
                    <div
                        style={{ marginLeft: 30 }}
                        className="operation-box"
                        onDragOver={handleDragOver}
                        onDrop={handleDropZone}
                    >
                        <div className="add-icon" />
                        <span className="grey-secondary-text mb-3">
                            drag here to clone
                        </span>
                    </div>
                </div>
            )}
            <div className="btn-holder">
                {isCloning ? (
                    <Spinner />
                ) : (
                    <button className="operation-btn" onClick={handleDropZone}>
                        Start Cloning
                    </button>
                )}
            </div>
        </div>
    );
}

export default CloneProject;
