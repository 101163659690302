import React, { useState, useEffect } from "react";
import localForage from "localforage";
import { ProjectAvailabityText } from "./selected-project.style";
import { useSelector } from "react-redux";
import useProjects from "helpers/custom-hooks/projects";
import { Spinner } from "components/Spinner/Spinner";
import useWindowDimensions from "helpers/custom-hooks/windowDimension";

const SelectedProject = ({ collapsible, handleCollapsible, mode }) => {
    const current = useSelector((state) => state.project.currentProject) || "";
    const projects = useSelector((state) => state.project.projects);
    const loading = useSelector((state) => state.project.projectsLoading);
    const { getProject, projectLoading } = useProjects();
    const [searchedProject, setSearchProject] = useState(projects);
    const [searchText, setSearchText] = useState("");
    const { width } = useWindowDimensions();

    async function handleChecked(e) {
        try {
            // Find the project based on the provided key
            const foundProject = projects.find(
                (project) => project.key === e.target.value
            );
            if (!foundProject) {
                throw new Error("Project not found");
            }

            const res = await localForage.getItem("single_project_credentials");
            if (!res) {
                throw new Error("Failed to retrieve credentials");
            }
            // Call the function to get the project with the found key and retrieved credentials
            getProject(foundProject.key, res);
        } catch (error) {
            throw new Error("An error occurred:", error);
        }
    }

    function handleRadioReponsiveness(project) {
        let projectName = "";
        if (project.name.length > 15 && width <= 1199) {
            projectName = project.name.slice(0, 20) + "...";
        } else if (project.name.length > 15 && width > 1199) {
            projectName = projectName = project.name.slice(0, 10) + "...";
        } else if (project.name) {
            projectName = project.name;
        }

        return <span>{projectName}</span>;
    }

    function handleChange(e) {
        setSearchText(e.target.value);
    }

    useEffect(() => {
        const results = projects.filter((project) =>
            project.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setSearchProject(results);
    }, [searchText, projects]);

    return (
        <>
            {projectLoading && (
                <div className="spinner-holder">
                    <div className="spinner-content">
                        <Spinner />
                        <div className="spinner-text">
                            fetching project information
                        </div>
                    </div>
                </div>
            )}
            <div className="project-title">
                <span className="title">Selected Project</span>
                <span className="close-holder">
                    {/* close <span className="close">&times;</span> */}
                </span>
            </div>
            <div className="current-project">
                <div className="current-project-icon"></div>
                <div className="project-description">
                    <span className="project-name">
                        {(current && current.name) || "Project name"}
                    </span>
                    <span className="project-id">
                        {(current && current.key) || "Project key"}
                    </span>
                </div>
            </div>
            <div className="projects">
                <span className="available-project-title">
                    Available Projects
                </span>
                <input
                    type="text"
                    onChange={handleChange}
                    placeholder="Search by project Name"
                    className="search-input"
                />
                <div className="radio-holder">
                    {loading ? (
                        <ProjectAvailabityText>
                            <Spinner />
                        </ProjectAvailabityText>
                    ) : projects.length <= 0 ? (
                        <ProjectAvailabityText>
                            You have no projects at the moment
                        </ProjectAvailabityText>
                    ) : (
                        projects &&
                        searchedProject.map((project, index) => {
                            return (
                                <label
                                    key={project.key}
                                    className={`radio-container ${
                                        current.key === project.key &&
                                        "active-radio"
                                    }`}
                                    title={project.name}
                                >
                                    <input
                                        type="radio"
                                        name="name"
                                        className={
                                            current.key === project.key &&
                                            " active-radio"
                                        }
                                        value={project.key}
                                        onChange={handleChecked}
                                        checked={current.key === project.key}
                                    />
                                    <span
                                        className="radio-text"
                                        title={project.name}
                                    >
                                        {handleRadioReponsiveness(project)}
                                    </span>
                                    <span class="checkmark" />
                                </label>
                            );
                        })
                    )}
                </div>
            </div>
            <div className="caret" onClick={handleCollapsible} role="button">
                {collapsible ? <span>&#8744;</span> : <span>&#8743;</span>}
            </div>
        </>
    );
};

export default SelectedProject;
