import React from "react";
import { MainWrapper, IconWrapper } from "./onBoarding.style";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

const OnBoardInput = React.forwardRef(
    ({ isChanged, config, isValid, error }, ref) => {
        return (
            <MainWrapper>
                <input
                    {...config}
                    autoFocus
                    ref={ref}
                    onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                    }}
                />
                <span className="error">{error}</span>
                <IconWrapper display={isChanged.toString()}>
                    {isValid ? (
                        <CheckCircleTwoTone twoToneColor="#0AEDC2" />
                    ) : (
                        <CloseCircleTwoTone twoToneColor="#F04F45" />
                    )}
                </IconWrapper>
            </MainWrapper>
        );
    }
);

export default OnBoardInput;
