import React from "react";
import { HeaderStyled } from "./header.style";
import { ReactComponent as Logo } from "assets/Logo/SC1.svg";

function Header() {
    return (
        <HeaderStyled>
            <div className="header">
                <div className="brand-name">
                    <Logo className="logo" />
                    <div className="title">Export & Count for Cloud</div>
                </div>
                <div className="other-options">
                    <div className="option">
                        {/* <BellIcon className="bell-icon" /> */}
                        {/* <span className="option-text">Notification</span> */}
                        {/* <span className="notifcation-count">3</span> */}
                    </div>
                    <div className="option">
                        {/* <PlusIcon className="plus-icon" /> */}
                        {/* <span className="option-text">New Instance</span> */}
                    </div>
                </div>
            </div>
        </HeaderStyled>
    );
}

export default Header;
