import { useState } from "react";
import { useSnackbar } from "notistack";
import Axios from "axios";
import { useSelector } from "react-redux";
import useReponseErr from "../reponseErr";

function useMigrate() {
    const [isMigrating, setIsMigrating] = useState(false);
    const [migrateSuccess, setMigrateSuccess] = useState(false);
    const [migrateSuccessData, setMigrateSuccessData] = useState({});
    const { responseErr } = useReponseErr();
    const tokenVal = useSelector((state) => state.project.bodyToken);
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();

    function migrate(values) {
        setIsMigrating(true);
        Axios.post("/migrate", {
            ...values,
            ...tokenVal,
        })
            .then((res) => {
                setMigrateSuccessData(res);
                setMigrateSuccess(true);
                enqueueSnackbar("Migrate Successful", {
                    variant: "success",
                });
                setIsMigrating(false);
            })
            .catch((err) => {
                setIsMigrating(false);
                responseErr(err);
                setMigrateSuccess(false);
            });
    }

    if (!isMigrating) {
        closeSnackbar("key");
    } else {
        enqueueSnackbar("Kindly wait while we migrate your project", {
            persist: true,
            variant: "default",
            key: "key",
        });
    }

    return {
        migrate,
        isMigrating,
        migrateSuccessData,
        migrateSuccess,
    };
}

export default useMigrate;
