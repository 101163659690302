import React, { useState, useEffect } from "react";
import { ReactComponent as VisualizeIcon } from "assets/Icons/visualize.svg";
import { ReactComponent as ExtractIcon } from "assets/Icons/extract.svg";
import { ReactComponent as CloneIcon } from "assets/Icons/clone.svg";
import { ReactComponent as MigrateIcon } from "assets/Icons/migrate.svg";
import { MainHeaderStyled, NavLinkMainStyled } from "./main-header.style";
import { Link } from "react-router-dom";
import { setCurrentDashboardScreen } from "store/sc";
import { useDispatch, useSelector } from "react-redux";

const MainContent = ({ mode }) => {
    const dispatch = useDispatch();
    const [migrateDestination, setMigrateDestination] = useState("");
    const [cloneDestination, setCloneDestination] = useState("");
    const currentDashboardScreen = useSelector((state) => state.project.type);
    useEffect(() => {
        if (
            currentDashboardScreen === "releases" ||
            currentDashboardScreen === "filters"
        ) {
            setMigrateDestination("/");
        } else {
            setMigrateDestination("/migrate");
        }
    }, [currentDashboardScreen]);

    useEffect(() => {
        if (
            currentDashboardScreen === "releases" ||
            currentDashboardScreen === "filters"
        ) {
            setCloneDestination("/");
        } else {
            setCloneDestination("/clone");
        }
    }, [currentDashboardScreen]);

    return (
        <MainHeaderStyled mode={mode}>
            {/* <div className="action-header">
                <span className="action-title">Actions</span>
                <span className="action-close">
                    <Link
                        to="/"
                        onClick={() =>
                            dispatch(
                                setCurrentDashboardScreen({ name: "root" })
                            )
                        }
                    >
                        {" "}
                        Go Home
                    </Link>
                </span>
            </div> */}
            <div className="links-holder">
                <NavLinkMainStyled
                    mode={mode}
                    to={`/extract`}
                    exact
                    style={({ isActive }) => ({
                        backgroundColor: isActive ? "#e37915" : "transparent",
                        color: isActive ? "#fff" : "#000",
                        border: isActive ? "" : "1px solid grey",
                    })}
                >
                    <span className="nav-text">Export</span>
                    <span className="icon-holder">
                        <ExtractIcon className="icon extract-icon" />
                    </span>
                </NavLinkMainStyled>
                <NavLinkMainStyled
                    mode={mode}
                    activeClassName="visualize-active"
                    to={`/visualize`}
                    exact
                    style={({ isActive }) => ({
                        backgroundColor: isActive ? "#009bc0" : "transparent",
                        color: isActive ? "#fff" : "#000",
                        border: isActive ? "" : "1px solid grey",
                    })}
                >
                    <span className="nav-text">Visualise</span>
                    <span className="icon-holder">
                        <VisualizeIcon className="icon visualize-icon" />
                    </span>
                </NavLinkMainStyled>
                <NavLinkMainStyled
                    mode={mode}
                    className={`${
                        currentDashboardScreen === "releases" ||
                        currentDashboardScreen === "filters"
                            ? "disable"
                            : ""
                    }`}
                    style={({ isActive }) => ({
                        backgroundColor: isActive ? "#f04f45" : "transparent",
                        color: isActive ? "#fff" : "#000",
                        border: isActive ? "" : "1px solid grey",
                    })}
                    to={cloneDestination}
                    exact
                >
                    <span className="nav-text">Clone</span>
                    <span className="icon-holder">
                        <CloneIcon className="icon clone-icon" />
                    </span>
                </NavLinkMainStyled>
                <NavLinkMainStyled
                    mode={mode}
                    className={`${
                        currentDashboardScreen === "releases" ||
                        currentDashboardScreen === "filters"
                            ? "disable"
                            : ""
                    }`}
                    style={({ isActive }) => ({
                        backgroundColor: isActive ? "#fdd800" : "transparent",
                        color: isActive ? "#fff" : "#000",
                        border: isActive ? "" : "1px solid grey",
                    })}
                    to={migrateDestination}
                >
                    <div className="nav-text">
                        <span className="nav-text">Migrate</span>
                    </div>
                    <span className="icon-holder">
                        <MigrateIcon className="icon migrate-icon" />
                    </span>
                </NavLinkMainStyled>
            </div>
        </MainHeaderStyled>
    );
};

export default MainContent;
