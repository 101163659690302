import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: false,
    plugins: {
        legend: {
            position: "top",
        },
    },

    scales: {
        x: {
            ticks: {
                color: "white",
            },
            grid: {
                color: "white", // Set grid lines color to white
            },
        },
        y: {
            ticks: {
                color: "white", // Set label color to white
            },
            grid: {
                color: "white", // Set grid lines color to white
            },
        },
    },
};

const BarChartView = ({ data }) => {
    console.log(data);
    return (
        <>
            <div style={{ width: "100%", overflowX: "scroll" }}>
                <Bar
                    height={"400px"}
                    width={"1200px"}
                    options={options}
                    data={data}
                />
            </div>
        </>
    );
};

export default BarChartView;
