import styled from "styled-components";

export const MainContentStyled = styled.div`
    background: #0e1923;
    box-shadow: 0 3px 15px rgba(20, 20, 20, 0.9);
    height: 100%;
    // height: ${(props) =>
        props.expandedView && props.isVisualize ? "84vh" : "100%"};
    border-radius: 30px;
    grid-area: operation-area;
    width: ${(props) =>
        props.expandedView && props.isVisualize ? "85.6vw" : "100%"};
    overflow-x: hidden;
    position: ${(props) =>
        props.expandedView && props.isVisualize ? "absolute" : "relative"};
    transition: width 300ms ease-in-out;
    color: ${(props) => (props.mode ? "#fff" : "#000")};
    border: 0.5px solid ${(props) => (props.mode ? "#786dc1" : "none")};
    z-index: 4;
`;
