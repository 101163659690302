import styled from "styled-components";

const HeaderStyled = styled.div`
    position: fixed;
    height: 10vh;
    width: 100vw;
    top: 0;
    padding-left: 90px;
    padding-right: 120px;
    background-color: #0e1923;
    grid-area: header;
    z-index: 2;
    display: flex;
    justify-content: space-between;

    .header {
        height: 100%;
        width: 100%;

        display: flex;
        align-items: center;
        margin-left: 30px;
        justify-content: space-between;

        .brand-name {
            display: flex;
            gap: 4px;
            align-items: center;

            .logo {
                font-size: 10px;
                width: 28px;
                height: 28px;
                margin-right: 5px;
            }

            .logo-text {
                display: none;
                font-size: 22px;
            }
            .title {
                font-size: 28px;
                font-weight: 500;
                letter-spacing: 1.3px;
                color: white;
            }
        }

        .other-options {
            display: flex;

            .option {
                margin-left: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .plus-icon,
                .bell-icon {
                    width: 28px;
                    height: 28px;
                }

                &-text {
                    margin-left: 10px;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: -0.1px;
                }

                .notifcation-count {
                    height: 22px;
                    width: 22px;
                    background: #06edc2;
                    color: #2a10e1;
                    border-radius: 50%;
                    position: absolute;
                    top: 10px;
                    left: -10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 13px;
                }
            }
        }
    }
`;

export { HeaderStyled };
