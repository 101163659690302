import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import { MainContentStyled } from "./cloud-content";
import Banner from "@atlaskit/banner";
import WarningIcon from "@atlaskit/icon/glyph/warning";
import Exports from "components/exports/exports";
import JiraServiceDeskCounts from "components/jira-service-desk-counts/jira-service-desk-counts";
import JiraSoftwareCounts from "components/jira-software-counts/jira-software-counts";
import SchemeCounts from "components/scheme-counts/scheme-counts";
import Attachments from "components/attachments/attachments";
import Axios from "axios";
import "./content.scss";
import { useDispatch, useSelector } from "react-redux";
import {
    updateJWT,
    updateSchemeData,
    updateServiceDeskData,
    updateSoftwareData,
} from "store/cloud-counter/CloudCounterSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollIndicatorButton from "components/ScrollIndicatorButton/ScrollIndicatorButton";

const CloudContent = ({ collapsible, selectedOption, expandedView, mode }) => {
    const dispatch = useDispatch();
    const { software_counts, service_desk_counts, scheme_counts, jwt } =
        useSelector((state) => state.cloudCounter);
    const [optionsMenu, showOptionsMenu] = useState(false);

    const [theme, setTheme] = useState("software-theme");
    const tokenVal = useSelector((state) => state.project.bodyToken);

    const location = useLocation();
    const transitions = useTransition(location.pathname, {
        from: { opacity: 0, transform: "translate3d(100%,0,0)" },
        enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
        leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
        config: { duration: 250 },
    });

    const isVisualize = location.pathname === "/visualize";

    const requestResources = (token) => {
        const resources = ["scheme", "software", "servicedesk"];
        const requests = resources.map((resource) => {
            return Axios.post(`/jira/${resource}/`, { token: token })
                .then((res) => {
                    const resourceData = res.data;
                    return {
                        response: resourceData,
                        resource,
                    };
                })
                .catch((err) => {
                    toast.error(
                        `Unable to get ${resource} data at the moment. Kindly try again by refreshing the page`,
                        { appearance: "error" }
                    );
                });
        });
        Promise.all(requests).then((results) => {
            results.forEach((result) => {
                if (result === undefined) return;
                const { previous, ...data } = result.response;
                let id = 0;

                const payload = [
                    { data, date: new Date().toISOString(), id: id++ },
                    ...previous.map((data) => ({
                        ...data,
                        id: id++,
                    })),
                ];
                console.log(result);

                switch (result.resource) {
                    case "software":
                        dispatch(updateSoftwareData(payload));
                        break;
                    case "servicedesk":
                        dispatch(updateServiceDeskData(payload));
                        break;
                    case "scheme":
                        dispatch(updateSchemeData(payload));
                        break;
                    default:
                        break;
                }
            });
        });
    };

    useEffect(() => {
        const location = window.location;
        const searchObj = new URLSearchParams(location.search.substr(1));
        const jwt = tokenVal.token;
        const lic = searchObj.get("lic");
        console.log(`APP_MODE is ${process.env.MODE} `);
        if (
            process.env.REACT_APP_MODE === "development" ||
            process.env.REACT_APP_NODE_ENV === "dev"
        ) {
            dispatch(updateJWT(jwt));
            requestResources(jwt);
        } else {
            if (!lic || lic.toLowerCase() !== "active") {
                toast.error(
                    `Unable to verify your license. Kindly reach out to your site admin`,
                    { appearance: "error" }
                );
            } else {
                dispatch(updateJWT(jwt));
                requestResources(jwt);
            }
        }
    }, []);

    const getViewData = useCallback(() => {
        switch (selectedOption) {
            case "software":
                return {
                    data: software_counts || [],
                    title: "Jira Software",
                };
            case "servicedesk":
                return {
                    data: service_desk_counts || [],
                    title: "Jira Service Management",
                };
            case "scheme":
                return { data: scheme_counts || [], title: "Scheme" };
            case "attachments":
                return { data: [], title: "Attachments" };
            default:
                return { data: [], title: "" };
        }
    }, [selectedOption]);

    return (
        <MainContentStyled
            mode={mode}
            expandedView={expandedView}
            className={`${collapsible && "collapse"}`}
            isVisualize={isVisualize}
        >
            <ScrollIndicatorButton />
            {transitions((style, pathname) => (
                <animated.div
                    key={pathname}
                    style={{
                        ...style,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div className="homepage">
                        <div className="content-view">
                            <div className="black-bg"></div>
                            <div className="result-page">
                                <div className="data-section bg-white">
                                    <div
                                        className={`banner-div ${
                                            jwt === null ? "hide" : "hide"
                                        }`}
                                    >
                                        <Banner
                                            appearance="warning"
                                            icon={
                                                <WarningIcon
                                                    label="Warning icon"
                                                    secondaryColor="inherit"
                                                />
                                            }
                                        >
                                            JWT is missing
                                        </Banner>
                                    </div>
                                    <main>
                                        <div className="output">
                                            {selectedOption === "management" ? (
                                                <JiraServiceDeskCounts />
                                            ) : null}

                                            {selectedOption === "software" ? (
                                                <JiraSoftwareCounts />
                                            ) : null}

                                            {selectedOption === "scheme" ? (
                                                <SchemeCounts />
                                            ) : null}

                                            {selectedOption ===
                                            "attachments" ? (
                                                <Attachments />
                                            ) : null}
                                        </div>
                                    </main>
                                </div>
                                <div
                                    className={`right-end ${
                                        optionsMenu ? "active" : ""
                                    }`}
                                >
                                    {optionsMenu && (
                                        <div
                                            className="close-menu-btn"
                                            onClick={() =>
                                                showOptionsMenu(false)
                                            }
                                        >
                                            <div className="bar bar-1"></div>
                                            <div className="bar bar-3"></div>
                                        </div>
                                    )}
                                    <div className="mb-5">
                                        <h2 className="mb-4">Export Report</h2>
                                        <Exports info={getViewData()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </animated.div>
            ))}
        </MainContentStyled>
    );
};

export default CloudContent;
