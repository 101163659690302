import React, { useState } from "react";
import CounterSideBarViewType from "components/cloud-counter/SelectedProject";
import CloudContent from "components/cloud-counter/CloudContent";
import { CloudCounterStyled } from "./cloudCounter.style";

const MainCloudCounter = ({ mode, expandedView, handleExpandedView }) => {
    const [selectedOption, setSelectedOption] = useState("software");

    return (
        <CloudCounterStyled>
            <CounterSideBarViewType
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
            />
            <CloudContent
                mode={mode}
                expandedView={expandedView}
                handleExpandedView={handleExpandedView}
                selectedOption={selectedOption}
            />
        </CloudCounterStyled>
    );
};

export default MainCloudCounter;
