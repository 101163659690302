import React, { useState, useEffect } from "react";
import localForage from "localforage";
import { ProjectAvailabityText } from "./selected-project.style";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "components/Spinner/Spinner";
import useWindowDimensions from "helpers/custom-hooks/windowDimension";
import { setCurrentRelease } from "store/sc";
import { setCurrentDashboardScreen } from "../../store/sc";

const SelectedRelease = ({ collapsible, handleCollapsible, loading, mode }) => {
    const current = useSelector((state) => state.project.currentRelease) || "";
    const dashboardData = useSelector((state) => state.project.dashboardData);
    const [searchedReleases, setSearchReleases] = useState(
        dashboardData?.releases
    );
    const [searchText, setSearchText] = useState("");
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();

    async function handleChecked(e) {
        try {
            const foundRelease = dashboardData.releases.find(
                (release) => release.id === e.target.value
            );
            if (!foundRelease) {
                throw new Error("Release not found");
            }

            dispatch(setCurrentRelease(foundRelease));
            const res = await localForage.getItem("single_project_credentials");
            if (!res) {
                throw new Error("Failed to retrieve credentials");
            }

            dispatch(setCurrentDashboardScreen({ name: "release" }));
        } catch (error) {
            throw new Error("An error occurred:", error);
        }
    }

    function handleRadioReponsiveness(project) {
        let name = "";
        if (project.name.length > 15 && width <= 1199) {
            name = project.name.slice(0, 20) + "...";
        } else if (project.name.length > 15 && width > 1199) {
            name = project.name.slice(0, 10) + "...";
        } else if (project.name) {
            name = project.name;
        }

        return <span>{name}</span>;
    }

    function handleChange(e) {
        setSearchText(e.target.value);
    }

    useEffect(() => {
        const results =
            dashboardData &&
            dashboardData.releases &&
            dashboardData.releases.filter((release) =>
                release.name.toLowerCase().includes(searchText.toLowerCase())
            );
        setSearchReleases(results);
    }, [searchText, dashboardData]);

    return (
        <>
            {loading && (
                <div className="spinner-holder">
                    <div className="spinner-content">
                        <Spinner />
                        <div className="spinner-text">
                            fetching project information
                        </div>
                    </div>
                </div>
            )}
            <div className="project-title">
                <span className="title">Selected Release</span>
                <span className="close-holder"></span>
            </div>
            <div className="current-project">
                <div className="current-project-icon"></div>
                <div className="project-description">
                    <span className="project-name">
                        {(current && current.name) || "Release name"}
                    </span>
                    <span className="project-id">
                        {(current && current.id) || "Release ID"}
                    </span>
                </div>
            </div>
            <div className="projects">
                <span className="available-project-title">
                    Available Releases
                </span>
                <input
                    type="text"
                    onChange={handleChange}
                    placeholder="Search by Release Name"
                    className="search-input"
                />
                <div className="radio-holder">
                    {loading ? (
                        <ProjectAvailabityText>
                            <Spinner />
                        </ProjectAvailabityText>
                    ) : dashboardData &&
                      dashboardData.releases &&
                      dashboardData.releases.length <= 0 ? (
                        <ProjectAvailabityText>
                            You have no release at the moment
                        </ProjectAvailabityText>
                    ) : (
                        dashboardData &&
                        searchedReleases &&
                        searchedReleases.map((release, index) => {
                            return (
                                <label
                                    key={release.id}
                                    className={`radio-container ${
                                        current.id === release.id &&
                                        "active-radio"
                                    }`}
                                    title={release.name}
                                >
                                    <input
                                        type="radio"
                                        name="name"
                                        className={
                                            current.id === release.id &&
                                            " active-radio"
                                        }
                                        value={release.id}
                                        onChange={handleChecked}
                                        checked={current.id === release.id}
                                    />
                                    <span
                                        className="radio-text"
                                        title={release.name}
                                    >
                                        {handleRadioReponsiveness(release)}
                                    </span>
                                    <span class="checkmark" />
                                </label>
                            );
                        })
                    )}
                </div>
            </div>
            <div className="caret" onClick={handleCollapsible} role="button">
                {collapsible ? <span>&#8744;</span> : <span>&#8743;</span>}
            </div>
        </>
    );
};

export default SelectedRelease;
