import React from "react";
import SelectedProject from "./selected-project";

function CounterSideBarViewType({ selectedOption, setSelectedOption }) {
    return (
        <SelectedProject
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
        />
    );
}

export default CounterSideBarViewType;
