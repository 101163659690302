import React, { useState } from "react";
import { OperationStyled, SelectProjectText } from "pages/Clone/cone.style";
// import { ReactComponent as DateIcon } from 'assets/Icons/calendar.svg';
// import { ReactComponent as TimeIcon } from 'assets/Icons/time.svg';
import { ReactComponent as DownloadIcon } from "assets/Icons/download.svg";
import { useSelector } from "react-redux";
import localForage from "localforage";
import useExtract from "helpers/custom-hooks/extract";
import { Spinner } from "components/Spinner/Spinner";

function Extract({ mode }) {
    const [attachmentStatus, setAttachmentStatus] = useState(false);
    const currentProject = useSelector((state) => state.project.currentProject);
    const { extract, isExtracting } = useExtract();

    function handleExtract() {
        localForage.getItem("single_project_credentials").then((res) => {
            extract(
                { ...res, project_key: currentProject.key },
                attachmentStatus
            );
        });
    }

    function handleCheckbox() {
        setAttachmentStatus((prevState) => !prevState);
    }

    return (
        <OperationStyled mode={mode}>
            <div className="operation-content">
                <div className="operation-header">
                    <span className="title">Export</span>
                    <div className="other-info">
                        <div className="project-id">
                            <span className="project-icon" />
                            <span>
                                {currentProject &&
                                    currentProject.name &&
                                    currentProject.name}
                            </span>
                        </div>
                    </div>
                </div>
                {currentProject && Object.keys(currentProject).length === 0 ? (
                    <SelectProjectText>
                        Select a project to Export
                    </SelectProjectText>
                ) : (
                    <div className="operation-body operation-extract">
                        <div
                            className="project-details"
                            style={{ cursor: "default" }}
                        >
                            <div className="project-name-id">
                                <h3 className="project-name">
                                    {currentProject && currentProject.name}
                                </h3>
                                <span className="grey-secondary-text">
                                    {currentProject && currentProject.key}
                                </span>
                            </div>
                            <div className="">
                                <h3 className="grey-primary-text">
                                    No of IssueTypes
                                </h3>
                                <span className="num">
                                    {currentProject &&
                                        currentProject.NoOfIssueTypes}
                                </span>
                            </div>
                            <div className="holder">
                                <div className="">
                                    <h3 className="grey-primary-text">
                                        No of Issues
                                    </h3>
                                    <span className="num">
                                        {currentProject &&
                                            currentProject.NoOfIssues}
                                    </span>
                                </div>
                                <DownloadIcon className="download-icon" />
                            </div>
                        </div>

                        <div style={{ margin: "20px 0" }}>
                            <label
                                htmlFor="checkbox"
                                style={{ cursor: "pointer" }}
                            >
                                <input
                                    style={{ cursor: "pointer" }}
                                    id="checkbox"
                                    type="checkbox"
                                    checked={attachmentStatus}
                                    onChange={handleCheckbox}
                                />
                                <span
                                    style={{ margin: "0 5px", color: "#fff" }}
                                >
                                    {" "}
                                    Attachments{" "}
                                </span>
                            </label>
                        </div>

                        <div className="btn-holder">
                            {isExtracting ? (
                                <Spinner />
                            ) : (
                                <>
                                    <button
                                        className="operation-btn"
                                        onClick={handleExtract}
                                    >
                                        Download
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </OperationStyled>
    );
}

export default Extract;
