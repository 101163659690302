import React, { useState, useEffect } from "react";
import ReportForm from "../../components/ReportForm/ReportForm";
import ReportView from "./view-report";
import Report from "../../components/Report/Report";
import SearchBar from "../../components/SearchBar/SearchBar";
import Placeholder from "../../components/placeholder/placeholder";
import Axios from "axios";
// import data from '../../data';
import "./style.css";
import { useSelector } from "react-redux";

const VisualizeCommunity = () => {
    const [reportForm, setReportForm] = useState(false);
    const [selectedReportNumber, setSelectedReportNumber] = useState(null);
    const [viewSelectedReport, setViewSelectedReport] = useState(false);
    const [sortingMenu, setSortingMenu] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [data, setData] = useState([]);
    const [displayedData, setDisplayedData] = useState([]);
    const token = useSelector((state) => state.project.bodyToken);

    // Click handler for view reports
    const handleClick = (index) => {
        setSelectedReportNumber(index);
        setViewSelectedReport(true);
        setSearchValue("");
    };

    // const requestReports = () =>{
    //     Axios.get("https://api.scrumcompanion.net/api/v1/reports")
    //     .then(
    //         res=>{
    //             setData(res.data)
    //             if(res.status === 200){
    //                 setTimeout(()=>{
    //                     setDisplayedData(res.data)
    //                 },2000)
    //             }
    //         }

    //     )
    // }

    useEffect(() => {
        Axios.get("https://api.scrumcompanion.net/api/v1/reports").then(
            (res) => {
                setData(res.data);
                if (res.status === 200) {
                    setTimeout(() => {
                        setDisplayedData(res.data);
                    }, 2000);
                }
            }
        );
    }, []);

    // Test loading
    // setTimeout(()=>{
    //     setDisplayedData(data)
    // },5000)

    // Sorting function
    const sorting = (criteria) => {
        if (criteria === "votes") {
            setDisplayedData(
                data.sort((a, b) => (b.upvotes > a.upvotes ? 1 : -1))
            );
        } else if (criteria === "status") {
            setDisplayedData(
                data.sort((a, b) => (b.status > a.status ? 1 : -1))
            );
        }
        setSortingMenu(false);
    };

    //Loading
    const loading = (pHeight, pWidth) => {
        let placeholders = [];
        for (let i = 0; i < 10; i++) {
            placeholders.push(
                <Placeholder
                    height={`${pHeight}`}
                    width={`${pWidth}`}
                    key={i}
                />
            );
        }
        return placeholders;
    };

    return (
        <div className="visualize-community-homepage">
            {/* { requestReports() } */}
            <header className="visualize-community-header">
                <div className="desktop-menu">
                    <div className="logo">
                        <img
                            src={require("../../assets/Logo/logo-main.svg")}
                            alt="logo"
                        />
                        <p className="logo-name">Community</p>
                    </div>

                    <nav>
                        <ul>
                            <li
                                className="menu-item"
                                onClick={() => setViewSelectedReport(false)}
                            >
                                Home
                            </li>
                            <li
                                className="menu-item cta"
                                onClick={() => setReportForm(true)}
                            >
                                Send Request
                            </li>
                        </ul>
                    </nav>
                </div>
                <SearchBar
                    viewSelectedReport={viewSelectedReport}
                    handleClick={handleClick}
                    data={data}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
            </header>
            <div
                className={`sorting ${
                    viewSelectedReport === false ? "show" : ""
                }`}
            >
                <div
                    className="sorting-title"
                    onClick={() => setSortingMenu(!sortingMenu)}
                >
                    <h4>Filter</h4>
                    <img
                        src={require("../../assets/Icons/sort.svg")}
                        alt="filter by"
                    />
                </div>

                <ul
                    className={`sorting-options ${
                        sortingMenu === true ? "show-sorting" : ""
                    }`}
                >
                    <li
                        className="sorting-option"
                        onClick={() => sorting("votes")}
                    >
                        By Ratings
                    </li>
                    <li
                        className="sorting-option"
                        onClick={() => sorting("status")}
                    >
                        By Status
                    </li>
                </ul>
            </div>

            <ReportForm
                setReportForm={setReportForm}
                reportForm={reportForm}
                token={token}
            />

            {viewSelectedReport === false ? (
                <main className="report-feed-section">
                    <section className="reports">
                        {displayedData.length === 0
                            ? loading("274.8px", "250px")
                            : displayedData.map((report, idx) => {
                                  return (
                                      <Report
                                          report={report}
                                          idx={idx}
                                          handleClick={handleClick}
                                          key={idx}
                                      />
                                  );
                              })}

                        <div className="pagination"></div>
                    </section>
                    <section className="rss-feed">
                        <h3 className="rss-feed-title">Top Reports</h3>
                        <div className="feed">
                            {displayedData.length === 0
                                ? loading("36px", "116px")
                                : displayedData.map((report, idx) => {
                                      return (
                                          <div
                                              className="feed-report"
                                              onClick={() =>
                                                  handleClick(report.id)
                                              }
                                              key={idx}
                                          >
                                              <h4>{report.topic}</h4>
                                          </div>
                                      );
                                  })}
                        </div>
                    </section>
                </main>
            ) : (
                <ReportView
                    selectedReportNumber={selectedReportNumber}
                    key={selectedReportNumber}
                    setViewSelectedReport={setViewSelectedReport}
                    data={data}
                />
            )}
        </div>
    );
};

export default VisualizeCommunity;
