/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import { OperationStyled, SelectProjectText } from "pages/Clone/cone.style";
import { useTransition, animated } from "react-spring";
import useWindowDimensions from "helpers/custom-hooks/windowDimension";
import useVisualize from "helpers/custom-hooks/visualize";
import localForage from "localforage";
import { useSelector } from "react-redux";
import { Spinner } from "components/Spinner/Spinner";
import { Select } from "antd";
import ChartBarLine from "./chartPie";

const { Option } = Select;

function Visualize({ mode, expandedView, handleExpandedView }) {
    const [chartNum, setChartNum] = useState(1);
    const { width } = useWindowDimensions();
    const [chartType, setChartType] = useState("bar");
    const [{ chartHeight, chartWidth }, setDimension] = useState({});
    const { visualize, data = {}, loading } = useVisualize();
    const currentProject = useSelector((state) => state.project.currentProject);
    const project = JSON.parse(localStorage.getItem("single_project_main"));

    useEffect(() => {
        try {
            localForage.getItem("single_project_credentials").then((res) => {
                visualize({ ...res, project_key: project && project.key });
            });
        } catch (err) {
            console.log(err);
        }
    }, []);

    function handlePrev() {
        if (chartNum === 1) {
            return;
        }
        setChartNum(chartNum - 1);
    }

    function handleNext() {
        if (chartNum === chartOneData.length) {
            return;
        }
        setChartNum(() => chartNum + 1);
    }

    function handleChartPosition(e) {
        setChartNum(parseInt(e.currentTarget.dataset.position));
    }

    useEffect(() => {
        try {
            let chartWidth = "";
            let chartHeight = "";
            if (expandedView) {
                chartWidth = 500;
                chartHeight = 350;
            } else if (!expandedView && width > 1199) {
                chartWidth = 250;
                chartHeight = 250;
            } else if (!expandedView) {
                chartWidth = 250;
                chartHeight = 350;
            }

            setDimension({
                chartWidth,
                chartHeight,
            });
        } catch (err) {
            console.log(err);
        }
    }, [expandedView, width]);

    const chartOneData = Object.entries(
        data && data.data["Status statusCategory"]
    ).map((el) => {
        return {
            title: el[0],
            name: el[0],
            value: el[1],
        };
    });

    console.log(currentProject);

    function handleChart() {
        let chart = [];

        chart = (
            <ChartBarLine
                type=""
                width={chartWidth}
                height={chartHeight}
                data={chartOneData && chartOneData}
                text={"Status Category"}
            />
        );

        return currentProject && Object.keys(currentProject).length === 0 ? (
            <SelectProjectText>Select a project to Visualize</SelectProjectText>
        ) : (
            chart
        );
    }

    const transitions = useTransition(chartNum, {
        config: { duration: 300 },
        from: { opacity: 0, transform: "translate3d(100%,0,0)" },
        enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
        leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
    });

    return (
        <OperationStyled mode={mode} className="expanded-view">
            <div className="operation-content">
                <div className="operation-header">
                    <span className="title">Visualize</span>
                    <div className="other-info">
                        <div className="project-id">
                            <span className="project-icon" />
                            <span>{currentProject && currentProject.name}</span>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    {loading ? (
                        <Spinner />
                    ) : (
                        transitions((props, key) => (
                            // return (
                            <animated.div style={props} key={key}>
                                <div className="visualise-data-container">
                                    <div className="visualize-data">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Status</th>
                                                    <th>Count</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data &&
                                                    data.counter_data &&
                                                    Object.entries(
                                                        data.counter_data
                                                    )
                                                        .sort(
                                                            (a, b) =>
                                                                b[1] - a[1]
                                                        )
                                                        .map((el, index) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {el[0]}
                                                                    </td>
                                                                    <td>
                                                                        {el[1]}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="chart">
                                        {data && handleChart()}
                                    </div>
                                </div>
                            </animated.div>
                        ))
                    )}
                </div>
                <div className="chart-operations">
                    <div className="dots"></div>
                </div>
            </div>
        </OperationStyled>
    );
}

export default Visualize;
