import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import { MainContentStyled } from "./main-content";

const MainContent = ({
    collapsible,
    handleExpandedView,
    expandedView,
    mode,
}) => {
    const location = useLocation();
    const transitions = useTransition(location.pathname, {
        from: { opacity: 0, transform: "translate3d(100%,0,0)" },
        enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
        leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
        config: { duration: 250 },
    });

    const isVisualize = location.pathname === "/visualize";

    return (
        <MainContentStyled
            mode={mode}
            expandedView={expandedView}
            className={`${collapsible && "collapse"}`}
            isVisualize={isVisualize}
        >
            {transitions((style, pathname) => (
                <animated.div
                    key={pathname}
                    style={{
                        ...style,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Outlet />
                </animated.div>
            ))}
        </MainContentStyled>
    );
};

export default MainContent;
