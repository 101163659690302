import React, { useState } from "react";
import { OperationStyled } from "pages/Clone/cone.style";
import { useSelector } from "react-redux";
import CloneSuccess from "./CloneSuccess";
import CloneProject from "./CloneProject";

function Clone() {
    const currentProject = useSelector((state) => state.project.currentProject);
    const [page, setPage] = useState(1);

    let content = null;

    switch (page) {
        case 1:
            content = <CloneProject setPage={setPage} />;
            break;
        case 2:
            content = <CloneSuccess setPage={setPage} />;
            break;
        default:
            content = null;
            break;
    }

    return (
        <OperationStyled>
            <div className="operation-content">
                <div className="operation-header">
                    <span className="title">Clone</span>
                    <div className="other-info">
                        <div className="project-id">
                            <span className="project-icon" />
                            <span>
                                <span>
                                    {currentProject && currentProject.name}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="operation-body">{content}</div>
            </div>
        </OperationStyled>
    );
}

export default Clone;
