import { configureStore } from "@reduxjs/toolkit";
import projectReducer from "./sc";
import cloudCounterReducer from "./cloud-counter/CloudCounterSlice";

const store = configureStore({
    reducer: {
        project: projectReducer,
        cloudCounter: cloudCounterReducer, // Add your slices here
    },
    devTools: process.env.NODE_ENV !== "production",
});

export default store;
