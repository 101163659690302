import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    jwt: "",
    software_counts: [{}],
    service_desk_counts: [{}],
    scheme_counts: [{}],
    confluence_counts: [{}],
};

const cloudCounterSlice = createSlice({
    name: "cloudCounter",
    initialState,
    reducers: {
        updateJWT: (state, action) => {
            state.jwt = action.payload;
        },
        updateSoftwareData: (state, action) => {
            state.software_counts = action.payload;
        },
        updateServiceDeskData: (state, action) => {
            state.service_desk_counts = action.payload;
        },
        updateSchemeData: (state, action) => {
            state.scheme_counts = action.payload;
        },
        updateResourceData: (state, action) => {
            state.confluence_counts = action.payload;
        },
    },
});

export const {
    updateJWT,
    updateSoftwareData,
    updateServiceDeskData,
    updateSchemeData,
    updateResourceData,
} = cloudCounterSlice.actions;

export default cloudCounterSlice.reducer;
