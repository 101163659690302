import React, { useState, useEffect, useMemo } from "react";
import "./SearchBar.css";

const SearchBar = ({
    viewSelectedReport,
    handleClick,
    data,
    searchValue,
    setSearchValue,
}) => {
    const [searchView, setSearchView] = useState(false);
    const handleSearchInput = (e) => {
        setSearchValue(e.target.value);
    };

    const searchResults = useMemo(() => {
        if (searchValue.length > 0) {
            return data.filter((i) => {
                let searchMatch = [];
                try {
                    searchMatch = i.topic
                        .toLowerCase()
                        .match(searchValue.toLowerCase());
                } catch (error) {
                    console.log(error);
                }
                return searchMatch;
            });
        } else {
            return [];
        }
    }, [searchValue, data]);

    useEffect(() => {
        if (searchResults.length > 0) {
            setSearchView(true);
        } else {
            setSearchView(false);
        }
    }, [searchResults]);

    return (
        <div
            className={`search-form ${
                viewSelectedReport === false ? "show-search" : ""
            }`}
        >
            <div
                className={`search-input ${
                    searchView ? "enable-search-view" : "disable-search-view"
                }`}
            >
                <input
                    type="text"
                    required
                    placeholder="Search report"
                    value={searchValue}
                    onChange={handleSearchInput}
                />
                <div className="search-btn">
                    <img
                        src={require("../../assets/Icons/search.svg")}
                        alt="search"
                    />
                </div>
            </div>
            <ul className="search-results">
                {searchResults.map((report, idx) => {
                    return (
                        <li key={idx} onClick={() => handleClick(report.id)}>
                            {report.topic}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default SearchBar;
