import React from "react";
import { SelectedProjectStyled } from "./selected-project.style";
import { FormAnimationNext } from "helpers/animation";

const SelectedProject = ({ selectedOption, setSelectedOption }) => {
    return (
        <SelectedProjectStyled>
            <FormAnimationNext>
                <div
                    onClick={() => setSelectedOption("software")}
                    className="current-project"
                >
                    <div className="current-project-icon"></div>
                    <div className="project-description">
                        <span className="project-name">Jira Software</span>
                    </div>
                </div>
            </FormAnimationNext>

            <FormAnimationNext>
                <div
                    onClick={() => setSelectedOption("scheme")}
                    className="current-project"
                >
                    <div className="current-project-icon"></div>
                    <div className="project-description">
                        <span className="project-name">Jira Scheme</span>
                    </div>
                </div>
            </FormAnimationNext>

            <FormAnimationNext>
                <div
                    onClick={() => setSelectedOption("management")}
                    className="current-project"
                >
                    <div className="current-project-icon"></div>
                    <div className="project-description">
                        <p className="project-name">
                            Jira Service <br /> Management
                        </p>
                    </div>
                </div>
            </FormAnimationNext>

            <FormAnimationNext>
                <div
                    onClick={() => setSelectedOption("attachments")}
                    className="current-project"
                >
                    <div className="current-project-icon"></div>
                    <div className="project-description">
                        <span className="project-name">Attachments</span>
                    </div>
                </div>
            </FormAnimationNext>
        </SelectedProjectStyled>
    );
};

export default SelectedProject;
