import styled from "styled-components";

const DashboardStyled = styled.div`
    display: grid;
    /* background: #f7f8ff; */
    grid-template-areas:
        "header header"
        "leftsidebar content";
        "rightsidebar rightsidebar";
    grid-template-columns: 80px auto;
    grid-template-rows: 10vh 78vh 6vh;
    grid-gap: 20px;
`;

const SpinnerHolder = styled.div`
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export { DashboardStyled, SpinnerHolder };
