import { useState } from 'react';
import { useSnackbar } from 'notistack';
import useReponseErr from '../reponseErr';
// import FileSaver from 'file-saver';
// import queryString from 'query-string';
// import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function useExtract() {
  const [isExtracting, setIsExtracting] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { responseErr } = useReponseErr();
  // const location = useLocation();
  // const query = location.search;
  // const value = queryString.parse(query);
  const tokenVal = useSelector((state) => state.project.bodyToken);

  // function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  //   const byteCharacters = atob(b64Data);
  //   const byteArrays = [];

  //   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //     const slice = byteCharacters.slice(offset, offset + sliceSize);

  //     const byteNumbers = new Array(slice.length);
  //     for (let i = 0; i < slice.length; i++) {
  //       byteNumbers[i] = slice.charCodeAt(i);
  //     }

  //     const byteArray = new Uint8Array(byteNumbers);
  //     byteArrays.push(byteArray);
  //   }

  //   const blob = new Blob(byteArrays, { type: contentType });
  //   return blob;
  // }

  function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };

    a.addEventListener('click', clickHandler, false);
    a.click();
  }

  function extract(reqData, attachmentStatus) {
    const BASE_URL = `https://${process.env.REACT_APP_API_URL}/api/v1`;

    setIsExtracting(true);


    fetch(`${BASE_URL}/csv`, {
      method: 'POST',
      body: JSON.stringify({
        attachments: attachmentStatus,
        ...reqData,
        ...tokenVal,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        downloadBlob(blob, reqData.project_key);
        setIsExtracting(false);
        enqueueSnackbar('Extract Successful', { variant: 'success' });
      })
      .catch((err) => {
        console.log(err);
        setIsExtracting(false);
        responseErr(err);
        console.error('error', err.response);
      });
  }

  if (!isExtracting) {
    closeSnackbar('key');
  } else {
    enqueueSnackbar('Kindly wait while we extract your project', { persist: true, variant: 'default', key: 'key' });
  }

  return {
    extract,
    isExtracting,
  };
}

export default useExtract;
