import styled from "styled-components";

export const MainWrapper = styled.div`
    width: 85%;
    margin: 0 auto;
    position: relative;
    input {
        width: 100%;
        border: none;
        border-bottom: 1px solid #b6b4c8;
        outline: none;
        padding: 7px 5px;
        font-size: 1.1rem;
    }
    input::placeholder {
        color: #bdbdbd;
        text-align: center;
        font-weight: 100;
        font-size: 0.8rem !important;
        font-family: "Montserrat", "Poppins", "Roboto", "Segoe UI", Arial,
            Helvetica, sans-serif;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .error {
        color: red;
        font-size: 13px;
    }
`;

export const IconWrapper = styled.span`
    display: ${(props) => (props.display === true ? "flex" : "none")};
    position: absolute;
    right: 10px;
    top: 0;
`;
