/* eslint-disable react-hooks/exhaustive-deps */
import "antd/dist/reset.css";
import React, { useEffect, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Route, useLocation, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { useDispatch } from "react-redux";
import "./App.css";
import Dashboard from "pages/Dashboard/index";
import LandingPage from "pages/Landing Page/index";
import VisualizeCommunity from "pages/Visualize Community/index";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import localforage from "localforage";
import useProjects from "helpers/custom-hooks/projects";
import { toggleModal, setBodyToken } from "store/sc";
import { getProjectFromStorage } from "helpers/utils";
import queryString from "query-string";
import ErrorBoundary from "helpers/errorBoundary";
import StorageError from "helpers/storageError";
import Landing from "pages/Landing";
import Extract from "pages/Extract";
import Clone from "pages/Clone";
import Visualize from "pages/Visualize/Index";
import Migrate from "pages/Migrate";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

library.add(faSpinner, faTimes);

localforage.config({
    name: "myApp",
    version: 1.0,
    storeName: "single_project",
    description: "single_project",
});

const theme = {
    colors: {
        primary_dark_blue: "#1D1554",
        secondary_light_blue: "#2B11E1",
        secondary_dark_blue: "#374DD0",
    },
};

const GlobalStyle = createGlobalStyle`
body{
  background:#1C1454;
  background: ${(props) => (props.mode ? "#1C1454" : "#fff")};
  height:100vh;
}
`;

const App = () => {
    const location = useLocation();
    const isPath = location.pathname.includes("/cloud-counter");

    useEffect(() => {
        const baseURL = `https://${process.env.REACT_APP_API_URL}/v1`;
        axios.defaults.baseURL = baseURL;
    }, [isPath]);

    const { projectSessionInit, loading } = useProjects();
    const dispatch = useDispatch();
    const project = getProjectFromStorage();
    const [toggle, setToggle] = useState(false);
    const [expandedView, setExpandedView] = useState(false);

    function handleExpandedView() {
        setExpandedView((prevState) => !prevState);
    }

    function handleToggle() {
        setToggle((prevState) => !prevState);
    }

    useEffect(() => {
        projectSessionInit();

        const query = location.search;
        const values = queryString.parse(query);

        if (values.jwt) {
            dispatch(toggleModal(false));
            console.log(values.jwt);
            dispatch(setBodyToken({ token: values.jwt }));
        } else if (Object.keys(project).length) {
            dispatch(toggleModal(false));
            dispatch(setBodyToken({ token: values.jwt }));
        } else {
            dispatch(toggleModal(true));
        }
    }, []);

    useEffect(() => {
        try {
            if (window.localStorage) {
            }
        } catch (e) {
            // Access denied :-(
            console.error(
                "========================================================="
            );
            console.error(
                "========================================================="
            );
            console.error(
                "========Can't access LocalStorage, Kindly Enable!========"
            );
            console.error(
                "========================================================="
            );
            console.error(
                "========================================================="
            );
            return <StorageError />;
        }
    }, [window.localStorage]);

    return (
        <ErrorBoundary>
            <SnackbarProvider
                maxSnack={1}
                preventDuplicate
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <GlobalStyle mode={toggle} />
                <ThemeProvider theme={theme}>
                    <ToastContainer />
                    <Routes>
                        <Route
                            path="/community"
                            element={<VisualizeCommunity />}
                        />
                        <Route path="/home" element={<LandingPage />} />
                        <Route
                            path="/"
                            element={
                                <Dashboard
                                    projectsLoading={loading}
                                    toggle={toggle}
                                    handleToggle={handleToggle}
                                    expandedView={expandedView}
                                    handleExpandedView={handleExpandedView}
                                />
                            }
                            children={[
                                <Route
                                    path="/"
                                    element={<Landing mode={toggle} />}
                                />,
                                <Route
                                    path="extract"
                                    element={<Extract mode={toggle} />}
                                />,
                                <Route
                                    path="clone"
                                    element={<Clone mode={toggle} />}
                                />,
                                <Route
                                    path="visualize"
                                    element={
                                        <Visualize
                                            mode={toggle}
                                            handleExpandedView={
                                                handleExpandedView
                                            }
                                            expandedView={expandedView}
                                        />
                                    }
                                />,
                                <Route
                                    path="migrate"
                                    element={<Migrate mode={toggle} />}
                                />,
                            ]}
                        />
                        <Route
                            path="/cloud-counter"
                            element={
                                <Dashboard
                                    projectsLoading={loading}
                                    toggle={toggle}
                                    handleToggle={handleToggle}
                                />
                            }
                        />
                    </Routes>
                </ThemeProvider>
            </SnackbarProvider>
        </ErrorBoundary>
    );
};

export default App;
