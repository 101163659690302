import React, { useState } from "react";
import Axios from "axios";
import "./attachments.scss";
import { useSelector } from "react-redux";

const Attachments = () => {
    const jwt = useSelector((state) => state.cloudCounter.jwt);
    const [responseData, setResponseData] = useState({});
    const [loading, isLoading] = useState(false);
    const [viewBtn, showViewBtn] = useState(true);
    const [output, showOutput] = useState(false);

    const attachmentRequest = () => {
        showViewBtn(false);
        isLoading(true);
        Axios.post("/jira/attachment/", { token: jwt }).then((res) => {
            const taskId = res.data.task_id;
            const attSocket = new WebSocket(
                `wss://${process.env.REACT_APP_API_URL}/jira/attachment/` +
                    taskId +
                    "/"
            );
            attSocket.onmessage = (e) => {
                const data = JSON.parse(e.data);
                const attachmentData = JSON.parse(data.message);
                setResponseData(attachmentData.attachments);
                isLoading(false);
                showOutput(true);
            };
        });
    };

    return (
        <div className="attachments">
            <button
                className={`view-attachments-btn ${
                    viewBtn === false ? "hide" : ""
                }`}
                onClick={attachmentRequest}
            >
                View Attachments
            </button>
            <div className={`loading ${loading === false ? "hide" : "show"}`}>
                <img
                    src={require("../../assets/loading_loop.gif")}
                    alt="loader"
                />
            </div>
            <div
                className={`attachments-output ${
                    output === true ? "show" : "hide"
                }`}
            >
                <div className="output-row">
                    <p className="title">Attachments</p>
                    <table className="attachment-table">
                        <thead>
                            <tr>
                                <th>Count</th>
                                <th>Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{responseData.count}</td>
                                <td>{responseData.total_size}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Attachments;
