import styled from "styled-components";

const ContentStyled = styled.div`
    color: #fff;
    padding-right: 30px;
    width: 100%;
    height: 78vh;
    grid-area: content;
    display: grid;
    grid-template-columns: 1.2fr 3fr;
    grid-template-rows: 1.1fr 3fr;
    grid-column-gap: 30px;
    grid-template-areas: "selected-project navbar" "operation-area  operation-area";

    &.collapse {
        grid-template-areas: "selected-project navbar" "selected-project  operation-area";
    }
    &.cloud {
        grid-template-columns: 1fr 4fr;
        grid-template-areas: "selected-project operation-area" "selected-project  operation-area";
    }
`;

export { ContentStyled };
