import React, { useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa";
import moment from "moment";
import styles from "./previous-counts.module.scss";

const PreviousCounts = ({ history, setCurrentViewId, currentViewId }) => {
    const [historyData, setHistoryData] = useState([]);

    useEffect(() => {
        if (history?.length > 0) {
            const data = history.map((item) => {
                let day = moment(item.date).format("LL");
                let time = moment(item.date).format("LT");
                return { date: item.date, day, time, id: item?.id };
            });
            setHistoryData(data);
        }
    }, [history]);

    return (
        <>
            <div className={`container-fluid mt-3 ${styles.historyCover}`}>
                <h4>Previous count</h4>
                <hr />
                <div className={styles.history}>
                    {historyData?.map((item) => (
                        <div
                            key={item?.date}
                            onClick={() => setCurrentViewId(item?.id)}
                            className={`${styles.container} ${
                                item?.id === currentViewId
                                    ? styles.activeData
                                    : styles.data
                            }`}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <FaCircle
                                    color={
                                        item?.id === currentViewId
                                            ? "rgba(255, 255, 255, 1)"
                                            : "rgba(0, 155, 192, 1)"
                                    }
                                    className="shadow"
                                />
                                <span
                                    style={{
                                        color: "white",
                                        marginLeft: "8px",
                                    }}
                                    className="ms-2"
                                >
                                    {item?.day}
                                </span>
                            </div>
                            <p style={{ color: "white" }} className="m-0">
                                {item?.time}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default PreviousCounts;
