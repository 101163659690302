import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowDown } from "assets/Icons/Vector.svg";
import "./ScrollIndicatorButton.scss";

const ScrollIndicatorButton = () => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition =
                (window.scrollY || document.documentElement.scrollTop) +
                window.innerHeight;
            const pageHeight = document.documentElement.scrollHeight;

            setIsVisible(scrollPosition < pageHeight - 100);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const scrollMore = () => {
        window.scrollBy({ top: 300 });
    };

    return (
        <button
            className={`scroll-button ${isVisible ? "show" : ""}`}
            onClick={scrollMore}
        >
            <ArrowDown />
        </button>
    );
};

export default ScrollIndicatorButton;
