import styled from "styled-components";

const RightSideBarStyled = styled.div`
    position: fixed;
    height: 6vh;
    width: 100vw;
    bottom: 0;
    padding-left: 90px;
    padding-right: 120px;
    background-color: #0e1923;
    box-shadow: 0 3px 15px rgba(20, 20, 20, 0.9);
    color: #fff;
    z-index: 2;
    grid-area: rightsidebar;
    border-left: 1px solid ${(props) => (props.mode ? "#786dc1" : "none")};
    display: flex;
    justify-content: space-between;

    .other-options {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        .docs,
        .support {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 20px;
            color: #fff;
            text-decoration: none;

            &:hover {
                text-decoration: none;
                outline: 0;
            }

            &:focus {
                outline: 0;
            }

            .support-icon {
                transform: rotate(270deg);
            }

            span {
                margin-right: 20px;
            }
        }
    }
`;

export { RightSideBarStyled };
