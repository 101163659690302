import { useState } from "react";
import { useSnackbar } from "notistack";
import Axios from "axios";
import { cloneSuccess as cloneSuccessAction } from "../../store/sc";
import { useDispatch, useSelector } from "react-redux";
import useReponseErr from "../reponseErr";
// import { useLocation } from 'react-router-dom';
// import queryString from 'query-string';

function useClone() {
    const [isCloning, setIsCloning] = useState(false);
    const [cloneSuccess, setCloneSuccess] = useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { responseErr } = useReponseErr();
    const dispatch = useDispatch();
    const tokenVal = useSelector((state) => state.project.bodyToken);

    function clone(values) {
        setIsCloning(true);
        Axios.post("/clone", {
            ...values,
            ...tokenVal,
        })
            .then((res) => {
                dispatch(cloneSuccessAction(res.data));
                enqueueSnackbar("Clone Successful", {
                    variant: "success",
                });
                setCloneSuccess(true);
                setIsCloning(false);
            })
            .catch((err) => {
                setIsCloning(false);
                responseErr(err);
            });
    }

    if (!isCloning) {
        closeSnackbar("key");
    } else {
        enqueueSnackbar("Kindly wait while we clone your project", {
            persist: true,
            variant: "default",
            key: "key",
        });
    }

    return {
        clone,
        isCloning,
        cloneSuccess,
    };
}

export default useClone;
