import React, { useState, useEffect, useCallback, useMemo } from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaFilePdf } from "react-icons/fa";
import { VscJson } from "react-icons/vsc";
import {
    exportAsPdfFile,
    formatDataForExport,
    formatDate,
    generatePreliminaryHeaders,
    sortObjectKeys,
    titleCase,
} from "../../utils/util.ts";
import * as XLSX from "xlsx";
import "./exports.scss";

const Exports = (props) => {
    const { info } = props;
    const [exportData, setExportData] = useState([]);
    const [excelHeader, setExcelHeader] = useState([]);
    const title = useMemo(() => {
        let tit = `${info?.title} Count`;
        return tit;
    }, [info]);

    useEffect(() => {
        if (info?.data?.length > 0) {
            let headers = ["Date"];
            const rest = info.data;
            const sortedData = sortObjectKeys(rest[0]?.data);
            sortedData.forEach((row) => {
                const title = titleCase(row[0]);
                headers.push(title);
            });

            setExcelHeader([headers]);

            let tempExportData = [];

            rest.forEach((prev) => {
                let holder = {};
                sortedData.forEach((row) => {
                    holder[row[0]] = prev.data[row[0]];
                });
                tempExportData.push({ date: formatDate(prev.date), ...holder });
            });
            setExportData(tempExportData);
        }
    }, [info]);

    const exportToExcelOrCsv = useCallback(
        (exportAsCsv = false) => {
            if (exportData && exportData.length) {
                const workbook = XLSX.utils.book_new();
                const worksheet = XLSX.utils.json_to_sheet(exportData);
                XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

                // customize header names
                XLSX.utils.sheet_add_aoa(worksheet, excelHeader);

                XLSX.writeFile(
                    workbook,
                    title + (exportAsCsv ? ".csv" : ".xlsx"),
                    {
                        compression: true,
                        bookType: exportAsCsv ? "csv" : "xlsx",
                    }
                );
            }
        },
        [exportData, excelHeader, title]
    );

    const exportToPdf = useCallback(() => {
        if (info?.data) {
            exportAsPdfFile(
                formatDataForExport(info?.data),
                title,
                generatePreliminaryHeaders(info?.data),
                "landscape"
            );
        }
    }, [info, title]);

    return (
        <div className="container">
            <div className="row">
                <div className="col" onClick={() => exportToExcelOrCsv()}>
                    <SiMicrosoftexcel />
                </div>
                <div className="col" onClick={exportToPdf}>
                    <FaFilePdf />
                </div>
                <div className="col" onClick={() => exportToExcelOrCsv(true)}>
                    <VscJson />
                </div>
            </div>
        </div>
    );
};

export default Exports;
