import React, { useEffect, useState, useMemo, useCallback } from "react";

import {
    titleCase,
    sortObjectKeys,
    formatDate,
    extractChartData,
} from "../../utils/util.ts";
import "./scheme-counts.scss";
import Table from "../Table/table";
import PreviousCounts from "../previous-counts/previous-counts";
import BarChartView from "../bar-chart/bar-chart.jsx";
import { useSelector } from "react-redux";

const SchemeCounts = (props) => {
    const [schemeCountItems, setSchemeCountItems] = useState([]);
    const [currentViewId, setCurrentViewId] = useState(1);

    const scheme_counts = useSelector(
        (state) => state.cloudCounter.scheme_counts
    );
    const { chartData } = useMemo(
        () => extractChartData(scheme_counts),
        [scheme_counts]
    );

    const sortedList = useMemo(
        () =>
            scheme_counts.length > 0
                ? sortObjectKeys(scheme_counts[0]?.data)
                : [],
        [scheme_counts]
    );

    const currentCount = scheme_counts[0]?.data;
    const previousCount = useMemo(
        () =>
            scheme_counts.find((info) => info.id === currentViewId)?.data || {},
        [currentViewId, scheme_counts]
    );

    const date = useMemo(() => {
        const selectedDate = scheme_counts.find(
            (info) => info.id === currentViewId
        )?.date;
        return formatDate(selectedDate, "LL");
    }, [currentViewId, scheme_counts]);

    const updateDataTable = useCallback(() => {
        const items = sortedList.map((row, id) => {
            const title = titleCase(row[0]);
            const currentValue = currentCount[row[0]];
            const previousValue = previousCount[row[0]] ?? 0;
            const percentageDiff = previousValue
                ? (
                      ((currentValue - previousValue) / previousValue) *
                      100
                  ).toFixed(2)
                : "N/A";

            return {
                id,
                title,
                currentCount: currentValue,
                previousCount: previousValue,
                percentageDiff: percentageDiff + "%",
            };
        });
        setSchemeCountItems(items);
    }, [currentCount, previousCount, sortedList]);

    const previousData = useMemo(() => scheme_counts.slice(2), [scheme_counts]);

    useEffect(() => {
        updateDataTable();
    }, [currentViewId, updateDataTable]);

    return (
        <div className="scheme-counts-body">
            <div className="mb-5">
                <h1>Jira Scheme Counts</h1>
                <p>{date}</p>
            </div>
            <div className="container-fluid text-center mt-3 mb-5">
                <BarChartView data={chartData} />
            </div>
            <div className="mt-5">
                <Table data={schemeCountItems} />
                {currentViewId > 1 && (
                    <div className="d-flex justify-content-end mt-5">
                        <button
                            className={`btn btn-primary`}
                            onClick={() => setCurrentViewId(1)}
                        >
                            Reset Table Data
                        </button>
                    </div>
                )}
            </div>
            <div className="mt-5">
                <PreviousCounts
                    history={previousData}
                    setCurrentViewId={setCurrentViewId}
                    currentViewId={currentViewId}
                />
            </div>
        </div>
    );
};

export default SchemeCounts;
